@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
body {
  font-family: "Quicksand", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
}

.text-red-500 {
  color: #ff0000; /* Replace with your desired text color */
}

.navLink {
  font-family: "Montserrat", sans-serif;
}
