.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 0.5rem;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background-color: red;
}

.swiper-button-next,
.swiper-button-prev {
  color: red !important;
}